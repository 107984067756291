.button {
  height: 45px;
  font-weight: 500;
  border-radius: 12px;
  width: 100%;
  transition: 0.5s all;
  font-size: 14px;
}

.button-black,
.p-button {
  @extend .button;
  color: #fff !important;

  border: $primary_color;
  background-color: $primary_color;

  &.disabled {
    opacity: 0.5;
    background-color: $primary_color !important;
  }

  &:hover {
    opacity: 0.7;
    background-color: $primary_color !important;
  }
}

.button-white {
  @extend .button;
  color: $primary_color;
  background-color: white;
  border: 1px solid $primary_gray_200;

  &.disabled {
    opacity: 0.5;
    background-color: $primary_gray_200;
  }

  &:hover {
    opacity: 0.9;
    background-color: $primary_gray_200;
  }
}

.debit {
  color: $error_500;
}

.credit {
  color: $green_500;
}

.gray-bg {
  border-radius: 16px;
  background: $primary_gray_25;
}

.app-table {
  padding: 5px;
  overflow-x: auto;

  .app-table-header {
    border-bottom: 1px solid $primary_gray_200;
    padding: 16px;
    background-color: $primary_gray_25;
    @media all and (max-width: 767px) {
      padding: 16px 5px;
    }
    h3 {
      font-family: $inter_font_family;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.48px;
      text-transform: uppercase;
    }
  }
  .app-table-body {
    overflow-y: auto;
    height: 75vh;
    & > div {
      padding: 16px;
      cursor: pointer;

      @media all and (max-width: 767px) {
        padding: 16px 5px;
      }

      &:nth-child(3n) {
        background-color: $primary_gray_25;
      }

      p {
        font-family: $inter_font_family;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.14px;
      }
    }
    & > div:not(:last-child) {
      border-bottom: 1px solid $primary_gray_200;
    }
  }
  .app-table-row {
    padding: 16px;
    &:nth-child(3n) {
      background-color: $primary_gray_25;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $primary_gray_200;
    }
    @media all and (max-width: 767px) {
      padding: 16px 5px;
    }
  }
}

.p-avatar-2 {
  & .p-avatar {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid $white;
  }
}

.form_group {
  width: 100%;
  margin-bottom: 15px;
  .form_control {
    width: 100%;
    position: relative;
    height: 45px;
    border-radius: 12px;

    &.phone_number {
      input {
        padding-left: 55px;
      }
    }

    .password {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      cursor: pointer;
    }

    .country_img_icon {
      position: absolute;
      width: 50px;
      height: 100%;
      top: 0;
      border-radius: 12px 0 0 12px;
      background-color: white;
      border: 0.1px solid $primary_gray_200;
      overflow: hidden;
      transition: 0.2s all ease-in;
    }
  }

  input:not(input[type="radio"]),
  select,
  textarea {
    height: 100%;
    display: block;
    padding: 10px;
    text-align: left !important;
  }
  input:not(input[type="radio"]),
  select,
  textarea,
  .dropdown {
    width: 100%;

    font-size: 14px !important;
    border-radius: 12px;
    border: 0.1px solid $primary_gray_200;
    background-color: white;
    transition: 0.2s all ease-in;
    &:focus,
    &:active {
      border-bottom: 2px solid $input_focus_outline !important;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      & + .country_img_icon {
        border-bottom: 2px solid $input_focus_outline !important;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    &.error {
      border-bottom: 2px solid $error_border_color !important;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      & + .country_img_icon {
        border-bottom: 2px solid $error_border_color !important;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    &:disabled {
      background-color: #f7f7f7 !important;
    }
  }

  .readOnly {
    background-color: #f7f7f7 !important;
    border: none !important;
    &:active {
      border-bottom: none !important;
      border-color: transparent !important;
    }
  }

  label {
    font-size: 12px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    line-height: 20px;
    color: $primary_gray_500;
  }

  .input-error {
    font-size: 11px;
    color: $error_text_color;
    text-align: left;
    margin: 0 !important;
    padding: 0 !important;
  }

  .info-text {
    font-size: 11px;
    margin: 0 !important;
    padding: 0 !important;
    text-align: right;
    color: black;
  }
}

.password-validator {
  margin: 15px 0;
  & > ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      gap: 10px;
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
}

.kyc-image-upload {
  height: 200px;
  border-radius: 12px;
}

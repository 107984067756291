.complete_profile_container,
.share_information_container,
.personal_details_container,
.address_details_container,
.upload_container,
.next_of_kin_container,
.verification_container,
.purpose_container,
.income_container,
.success_container,
.secure_container,
.confirm_container,
.password_container {
  max-width: 450px;
  margin-inline: auto;
  .actions {
    display: flex;
    gap: 24px;
    margin-top: auto;
    width: 100%;
    @media all and (max-width: 480px) {
      & {
        gap: 12px;
      }
    }
    app-back-btn {
      width: 180px;
    }
    app-submit-btn {
      width: 100%;
    }
  }
}

.complete_profile_container,
.share_information_container,
.verification_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  & > h2 {
    margin-bottom: 40px;
  }
  & > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 80px;
    height: 100%;
    p {
      max-width: 360px;
      text-align: center;
      margin-bottom: 40px;
    }
    app-submit-btn {
      width: 300px;
    }
  }
}

.complete_profile_container {
  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $black;
        color: $white;
      }

      .status {
        display: flex;
        gap: 4px;
        margin-left: auto;
      }
    }
  }
}

.personal_details_container,
.address_details_container,
.next_of_kin_container,
.purpose_container,
.income_container,
.secure_container,
.confirm_container,
.password_container {
  & > h2 {
    margin-top: 24px;
  }
  & > p {
    margin-top: 8px;
    margin-bottom: 40px;
  }
}

.personal_details_container {
  form {
    fieldset {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      label {
        color: black;
        a {
          color: $blue_500;
          text-decoration: none;
        }
      }
    }
  }
}

.upload_container {
  height: 100%;
  form {
    display: flex;
    flex-direction: column;
    height: 90%;
    & > h2 {
      margin-top: 24px;
    }
    & > p {
      margin-bottom: 40px;
    }
    fieldset {
      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        background-color: $primary_gray_50;
        border-radius: 24px;
        padding: 70px 0;
        cursor: pointer;
      }
    }
    .camera-container {
      .snap-btn-container {
        margin-top: 20px;
        div {
          height: 60px;
          width: 60px;
          border: 1px solid $primary_gray_400;
          border-radius: 50%;

          button {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: $primary_color;
          }
        }
      }
    }
  }
}

.purpose_container,
.income_container {
  form {
    fieldset {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      &:not(:last-child) {
        border-bottom: 1px solid $primary_gray_100;
      }
    }
  }
}

.success_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  & > img {
    height: 160px;
    width: 160px;
    margin-bottom: 24px;
  }
  & > h2 {
    margin-bottom: 5px;
    text-align: center;
  }
  & > p {
    max-width: 360px;
    text-align: center;
    margin-bottom: 48px;
  }
  & > app-submit-btn {
    width: 100%;
    max-width: 360px;
  }
  & > span {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    white-space: nowrap;
  }
}

.secure_container,
.confirm_container {
  height: 50%;
  & > h2 {
    margin-bottom: 8px;
  }
  & > p {
    margin-bottom: 24px;
  }
  .banner {
    display: flex;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 72px;
  }
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    height: 100%;
    p {
      max-width: 360px;
      text-align: center;
    }
  }
}

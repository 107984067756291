.table {
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      border-top: 1px solid $primary_gray_200;
      border-bottom: 1px solid $primary_gray_200;
      background-color: $primary_gray_25;
      tr {
        th {
          padding: 0.5rem 1rem;
          h3 {
            font-family: $inter_font_family;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.48px;
            text-transform: uppercase;
            color: $primary_gray_500;
            white-space: nowrap;
          }
        }
      }
    }
    tbody {
      tr {
        color: $primary_gray_500;
        border: none;
        &:not(:last-child) {
          border-bottom: 1px solid $primary_gray_200;
        }
        span {
          font-family: $inter_font_family;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.14px;
        }
        td {
          padding: 1rem;
        }
      }
    }
  }
}

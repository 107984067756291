.login-wrapper {
  padding: 50px 0;

  form {
    padding: 30px 0;
  }
}

.login-options {
  height: 50px;
  & > div {
    height: 100%;
  }

  .remember-me {
    margin-top: -5px;
    margin-left: 5px;
  }

  a {
    margin-top: -5px;
  }
}

.btn-container {
  margin-top: 40px;
}
.remember-me {
  color: #4f555f;
}

.login-header {
  @media all and (max-width: 991px) {
    & {
      text-align: center;
    }
  }
}

/* You can add global styles to this file, and also import other style files */

@import "primeicons/primeicons.css";
@import "./assets/styles/apploader";
@import "./assets/styles/sharpgrotesk";
@import "./assets/styles/variables";
@import "./assets/styles/login";
@import "./assets/styles/form";
@import "./assets/styles/signup&profile";
@import "./assets/styles/modal";
@import "./assets/styles/apputilities";
@import "./assets/styles/autocomplete";
@import "./assets/styles/toast";
@import "./assets/styles/table";

:root {
  --animate-duration: 400ms;
  --animate-delay: 0.5s;
}

.table-element-animation {
  --animate-duration: 100ms;
  --animate-delay: 0.1s;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: auto;
}

*,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
input,
select,
textarea {
  border: 0;
  &:focus {
    outline: none !important;
  }
}

a {
  text-decoration: none;
  color: $blue_500;
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}

button {
  border: 0;
  background: transparent;
  &:focus {
    outline: none;
  }
}

.font_bold {
  font-family: $grotesk_font_family;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: -0.64px;

  @media all and (max-width: 991px) {
    font-size: 22px;
  }

  @media all and (max-width: 480px) {
    font-size: 20px;
  }
}

.font_bold_md {
  @extend .font_bold;
  font-size: 24px !important;
  font-weight: 300 !important;
}

.font_bold_md_500 {
  @extend .font_bold_sm;
  font-weight: 500;
}

.font_bold_sm {
  @extend .font_bold;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.font_grotesk_light {
  @extend .font_bold_sm;
  font-weight: 300;
  font-size: 14px !important;
}

.font_bold_md_white {
  @extend .font_bold;
  color: #fff;
  font-size: 18px !important;
}

.font_normal {
  font-family: $inter_font_family;
}

.font_sub_text {
  color: $secondary_color;
  font-size: 14px;
  font-family: $inter_font_family;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.font_title_normal {
  color: $black;
  font-family: $grotesk_font_family;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.font_xs {
  font-size: 12px;
  font-family: $inter_font_family;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.12px;
}

.font_sm {
  font-size: 13.5px;
  font-family: $inter_font_family;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.font_md {
  font-size: 16px;
  font-family: $inter_font_family;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.font_lg {
  font-family: $inter_font_family;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.18px;
}

.font_xs_normal {
  @extend .font_xs;
  color: $primary_gray_400;
  font-weight: 400;
}

.font_xs_medium {
  @extend .font_xs;
  font-weight: 500;
}

.font_sm_normal {
  @extend .font_sm;
  color: $primary_gray_300;
  font-weight: 400;
}

.font_sm_medium {
  @extend .font_sm;
  color: $primary_gray_500;
  font-weight: 500;
}

.font_md_normal {
  @extend .font_md;
  color: $black;
  font-weight: 400;
}

.font_md_medium {
  @extend .font_md;
  color: $black;
  font-weight: 500;
}

.font_lg_medium {
  @extend .font_lg;
  font-weight: 500;
}

.black {
  color: $black;
}

.bg_black {
  background-color: $black;
}

.white {
  color: $white;
}

.bg_white {
  background-color: $white;
}

.sky_blue_100 {
  color: $sky_blue_100;
}

.sky_blue_200 {
  color: $sky_blue_200;
}

.sky_blue_500 {
  color: $sky_blue_500;
}

.sky_blue_600 {
  color: $sky_blue_600;
}

.blue_500 {
  color: $blue_500;
}

.blue_600 {
  color: $primary_blue_600;
}

.green_200 {
  color: $green_200;
}

.green_400 {
  color: $green_400;
}

.green_500 {
  color: $green_500;
}

.green_600 {
  color: $green_600;
}

.orange_100 {
  color: $orange_100;
}

.orange_200 {
  color: $orange_200;
}

.orange_300 {
  color: $orange_300;
}

.orange_400 {
  color: $orange_400;
}

.orange_500 {
  color: $orange_500;
}

.orange_600 {
  color: $orange_600;
}

.gray_500 {
  color: $primary_gray_500;
}

.gray_200 {
  color: $primary_gray_200;
}

.gray_300 {
  color: $primary_gray_300;
}

.gray_400 {
  color: $primary_gray_400;
}

.gray_500 {
  color: $primary_gray_500;
}

.bg_white {
  background-color: $white;
}

.bg_orange_100 {
  background-color: $orange_100;
}

.bg_orange_200 {
  background-color: $orange_200;
}

.bg_orange_300 {
  background-color: $orange_300;
}

.bg_orange_400 {
  background-color: $orange_400;
}

.bg_orange_500 {
  background-color: $orange_500;
}

.bg_orange_600 {
  background-color: $orange_600;
}

.bg_blue_100 {
  background-color: $primary_blue_100;
}

.bg_blue_400 {
  background-color: $primary_blue_400;
}

.bg_gray_25 {
  background-color: $primary_gray_25;
}

.bg_gray_50 {
  background-color: $primary_gray_50;
}

.bg_gray_100 {
  background-color: $primary_gray_100;
}

.bg_gray_200 {
  background-color: $primary_gray_200;
}

.bg_gray_300 {
  background-color: $primary_gray_300;
}

.bg_green_100 {
  background-color: $green_100;
}

.bg_green_200 {
  background-color: $green_200;
}

.bg_green_400 {
  background-color: $green_400;
}

.bg_green_500 {
  background-color: $green_500;
}

.bg_sky_blue_100 {
  background-color: $sky_blue_100;
}

.bg_sky_blue_200 {
  background-color: $sky_blue_200;
}

.bg_sky_blue_500 {
  background-color: $sky_blue_500;
}

.bg_error_100 {
  background-color: $error_100;
}

.bg_error_500 {
  background-color: $error_500;
}

.error_100 {
  color: $error_100;
}

.error_400 {
  color: $error_border_color;
}

.error_500 {
  color: $error_500;
}

.warning_500 {
  color: $warning_500;
}

.inputswitchLabel {
  @extend .font_sub_text;

  @media all and (max-width: 767px) {
    font-size: 12px;
  }
}

.row,
.row > * {
  padding: 0;
  margin: 0;
}

.anchor_link {
  color: $link_color;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.p-inputswitch {
  height: 25px;
  width: 45px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #3fb12c !important;
}
.p-inputswitch .p-inputswitch-slider:before {
  height: 16px;
  width: 16px;
  margin-top: -0.5rem;
}

.otp-input-container {
  display: flex;
  justify-content: space-evenly;

  .otp-input {
    height: 60px !important;
    width: 60px !important;
    border: 1px solid $login_input_border !important;
    border-radius: 12px !important;
    font-weight: bolder;

    &.error {
      border: 1px solid $error_500 !important;
      color: $error_500;
      &:focus {
        border: 1px solid $error_500 !important;
      }
    }

    &:focus {
      border-bottom: 2px solid $input_focus_outline !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    @media all and (max-width: 767px) {
      height: 45px !important;
      width: 45px !important;
    }
  }
}

.camera-container {
  .webcam-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;

    video {
      height: 260px !important;
      width: 260px !important;
      border-radius: 50%;
      aspect-ratio: auto;
      border: 6px solid $primary_gray_200;
      object-fit: cover;
    }

    img {
      border-color: #8be27c;
    }
  }
}

.transaction-information {
  background-color: white;
  border-radius: 16px;

  & > div:last-child {
    border-top: 1px solid $primary_gray_100;
  }
}

.p-tooltip-text {
  font-size: 12px;
  color: white;
  background-color: black !important;
}

$grotesk_font_family: "SharpGrotesk";
$inter_font_family: "Inter";
$secondary_color: #868c98;
$primary_color: #000000;
$secondary_background_color: #f9fafb;
$link_color: #597abf;

$black: #000000;
$white: #ffffff;

$blue_500: #597abf;

$sky_blue_100: #f2fcfe;
$sky_blue_200: #e4fafc;
$sky_blue_500: #50b8c3;
$sky_blue_600: #0d6f82;

$green_100: #edfaea;
$green_200: #e7fae3;
$green_400: #8be27c;
$green_500: #55a249;
$green_600: #1f6f15;

$orange_100: #fbf4ee;
$orange_200: #fbdfc6;
$orange_300: #fac394;
$orange_400: #f9a06c;
$orange_500: #de7d54;
$orange_600: #9f4b13;

$error_100: #fef3f2;
$error_500: #b42318;
$warning_500: #dc6803;

$primary_gray_25: #f9fafb;
$primary_gray_50: #f7f7f7;
$primary_gray_100: #f2f4f7;
$primary_gray_200: #e1e4ea;
$primary_gray_300: #b7bbc3;
$primary_gray_400: #868c98;
$primary_gray_500: #667085;

$login_input_border: #e1e4ea;
$input_focus_outline: #c0d2f9;
$error_border_color: #f04438;
$error_text_color: #681002;

$primary_blue_100: #f2f6fe;
$primary_blue_200: #e6edfd;
$primary_blue_300: #c0d2f9;
$primary_blue_400: #9fb5ec;
$primary_blue_600: #083087;

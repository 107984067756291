.p-toast {
  width: 600px !important;
  max-width: 95%;
  opacity: 1;
  z-index: 10000000000000001 !important;

  .toast-slider {
    height: 3px;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .p-toast-message {
    border-radius: 20px;
    border: 1px solid $primary_gray_100 !important;
    background: white !important;
    color: black !important;

    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.04), 0px 4px 20px -4px rgba(16, 24, 40, 0.1);

    .p-toast-message-content {
      padding: 12px 12px 12px 20px;
      border-width: 0;
      align-items: normal !important;
    }
    .p-toast-icon-close {
      display: none !important;
    }
  }
}

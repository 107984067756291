.p-dropdown {
  width: 100%;
  &.p-focus {
    border-color: initial !important;
    box-shadow: none !important;
  }

  .p-inputtext {
    font-size: 14px;
    &:focus {
      outline: none !important;
      border-color: initial;
      box-shadow: none;
    }
  }

  .p-overlay.p-component {
    min-width: 100% !important;
  }
}

.dropdown_content {
  max-height: 350px;
  border-radius: 16px;
  box-shadow: 0px 6.251833915710449px 23.444377899169922px 0px rgba(199, 196, 196, 0.25);
  background-color: white;
  top: 50px;
  display: none;
  overflow: scroll;

  &.beneficiaries {
    display: block;
    max-height: 400px;
  }

  .dot-transfers {
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid $primary_gray_100;
  }

  .other-banks {
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid $primary_gray_100;
    }
  }
}

.network-status {
  background-color: $primary_blue_100;
  border-top: 0.1px solid $primary_gray_500;
  border-radius: 0px 0px 12px 12px;
  p {
    font-size: 10px;
  }
}

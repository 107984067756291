.modal-dialog {
  pointer-events: all;
  &-sm {
    @extend .modal-dialog;
    width: 400px;
  }
  width: 450px;
  max-width: 90%;
  border-radius: 16px !important;
  .p-dialog-header {
    display: none !important;
  }

  .p-dialog-content {
    border-radius: 16px !important;
    padding: 0 !important;
  }
}

.p-sidebar-right.tiers-container {
  width: 500px;
  max-width: 100%;
  .p-sidebar-header {
    display: block !important;
    padding: 1rem;
  }
}

.p-sidebar-right {
  &.points-container,
  &.notifications-container {
    width: 500px;
    max-width: 100%;
    .p-sidebar-content {
      overflow-y: initial;
    }
  }
}

.p-overlaypanel {
  .p-overlaypanel-content {
    padding: 0.75rem;
    li {
      border-radius: 0.5rem;
      transition: all 0.3s;
      &:hover {
        background-color: $primary_gray_50;
      }
    }
    div {
      input {
        &::placeholder,
        &::-ms-input-placeholder {
          color: $primary_gray_400;
        }
      }
    }
  }
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.2) !important;
  backdrop-filter: blur(2px);
}

.p-sidebar {
  &-header {
    display: none !important;
  }

  &-footer {
    display: none !important;
  }

  &-content {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
  }
  &.p-sidebar-left {
    width: 20rem !important;

    @media all and (max-width: 767px) {
      width: 65% !important;
    }
  }
}

.transferSidebar {
  min-height: 20vh !important;
  max-height: 85vh !important;
  height: auto !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;

  .p-sidebar-header {
    display: block !important;
    padding: 1rem 1.25rem;
  }

  .p-sidebar-content {
    height: fit-content !important;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .p-sidebar-icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border: 1px solid $primary_color !important;
  }
}
.modal-heading {
  background-color: $primary_color;
  position: relative;
  overflow: hidden;

  h3,
  button {
    z-index: 1;
  }

  &-bg {
    position: absolute;

    &#top {
      left: -5%;
    }
    &#bottom {
      right: -5%;
    }
  }
}

.loading {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  & p {
    margin-top: 10px;
    margin-right: 25px;
    text-transform: uppercase;
    color: #fff;
    animation: fadeInOut 0.8s ease-in infinite alternate;
  }

  & .loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;

    & .loader-bg {
      height: 4px;
      background-color: rgba(255, 255, 255, 0.2);

      & .loader-fg {
        height: 4px;
        background-color: black;
        width: 50%;
        margin: 0 auto;
        animation: loaderGrowth 0.5s ease-in infinite;
      }
    }
  }

  & .image-cover {
    position: relative;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    & .animate-logo {
      z-index: 5;
      animation: fadeInOut 0.8s ease-in infinite alternate;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 4;
      background-position: bottom center;
      height: 388px;
      opacity: 0.95;
    }
  }
}

@keyframes loaderGrowth {
  0% {
    width: 20%;
  }
  60% {
    width: 60%;
  }
  100% {
    width: 100%;
  }
}
@keyframes fadeInOut {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.big-loader {
  min-height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.843);
  z-index: 3;
}
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: black !important;
}

.skeleton-loader {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.3;
  }
}

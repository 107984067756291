@font-face {
  font-family: "SharpGrotesk";
  src: local("SharpGrotesk"), url("../fonts/sharpgrotesk-font-family/SharpGrotesk-Light25.otf");
  font-weight: 100;
}

@font-face {
  font-family: "SharpGrotesk";
  src: local("SharpGrotesk"), url("../fonts/sharpgrotesk-font-family/SharpGrotesk-Medium25.otf");
  font-weight: 500;
}
@font-face {
  font-family: "SharpGrotesk";
  src: local("SharpGrotesk"), url("../fonts/sharpgrotesk-font-family/SharpGrotesk-SemiBold25.otf");
  font-weight: 700;
}
@font-face {
  font-family: "SharpGrotesk";
  src: local("SharpGrotesk"), url("../fonts/sharpgrotesk-font-family/SharpGrotesk-Bold25.otf");
  font-weight: 900;
}
